import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

function Home() {
  const [expand, setExpand] = useState(false);
  const navigate = useNavigate();

  React.useEffect(() => {
    const token = Cookies.get('token');
    if (token) {
      navigate('/chat');
    }
  }, [navigate]);

  const handleClick = () => {
    setExpand(true);
    setTimeout(() => {
      navigate('/login');
    }, 600); // Adjust time to match the duration of the expansion animation
  };

  return (
    <div className="min-h-screen flex flex-col justify-center items-center bg-gray-50">
      <div className={`text-center mb-8 transition-opacity duration-100 ${expand ? 'opacity-0' : 'opacity-100'}`}>
        <h1 className="text-7xl font-bold text-hxmBlue-500 mb-4">
          QUYD
        </h1>
        <h1 className="text-lg font-bold text-hxmBlue-500 mb-4">Questionnez vos documents</h1>
        <h2 className="text-2xl text-gray-600">
          Pour Tessi
        </h2>
        <h2 className="text-lg text-gray-600 mb-4">
          By Hexamind
        </h2>
        <h3>
          Veuillez vous connecter pour continuer
        </h3>
      </div>
      <button
        className={`py-3 px-6 bg-hxmBlue-500 text-white rounded-lg shadow-md hover:bg-blue-600 transition-all duration-700 ease-in-out transform ${expand ? 'fixed' : ''}`}
        style={expand ? {
          top: '50%',
          left: '45%',
          width: '240px',
          height: '165px',
          transform: 'translate(0%, -50%) scale(2)',
          backgroundColor: 'white',
          borderRadius: '0.75rem',
        } : {}}
        onClick={handleClick}
      >
        {!expand && 'Se connecter'}
      </button>
    </div>
  );
}

export default Home;

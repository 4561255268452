import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { RiExpandLeftLine, RiExpandRightLine, RiLogoutCircleLine, RiAddLine } from 'react-icons/ri';
import { MdOutlineAdminPanelSettings } from 'react-icons/md';
import { IoChatbubblesOutline} from 'react-icons/io5';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import logo from './icon/20240504_HXM_logo_with_name.png';

const Sidebar = ({ sidebarOpen, setSidebarOpen, handleLogout, role }) => {
  const navigate = useNavigate();
  const [showUserInfo, setShowUserInfo] = useState(false);
  const userInfoPopupRef = useRef(null);

  const handleSidebarToggle = () => {
    setSidebarOpen(!sidebarOpen);
    localStorage.setItem('chatSidebarOpen', JSON.stringify(!sidebarOpen));
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (userInfoPopupRef.current && !userInfoPopupRef.current.contains(event.target)) {
        setShowUserInfo(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleUserInfoToggle = () => {
    setShowUserInfo(!showUserInfo);
  };

  return (
    <div className={`fixed flex flex-col justify-between h-full transition-all duration-300 ${sidebarOpen ? 'w-64' : 'w-20'} bg-gray-200 shadow-inner`}>
      <div className="p-4 flex flex-col items-start">
        <button onClick={handleSidebarToggle} className="text-gray-600">
          {sidebarOpen ? (
            <RiExpandLeftLine className="text-3xl bg-gray-300 p-1 rounded-full hover:bg-hxmBlue-200 transition-colors duration-300" />
          ) : (
            <RiExpandRightLine className="text-3xl bg-gray-300 p-1 rounded-full hover:bg-hxmBlue-200 transition-colors duration-300" />
          )}
        </button>
        {sidebarOpen && <img src={logo} alt="Company Logo" className="my-5 w-32 transition-all duration-300" />}
        <div className="flex items-center mt-4">
          <button onClick={() => navigate('/chat')} className="text-gray-600 flex items-center font-semibold text-sm font-sans">
            <span className="bg-gray-300 p-1 rounded-full mr-2 hover:bg-hxmBlue-200 transition-colors duration-300">
              <IoChatbubblesOutline className="text-2xl" />
            </span>
            {sidebarOpen && <span className="font-sans">Chat</span>}
          </button>
        </div>
        <div className="flex items-center mt-4 relative">
          <button
            onClick={handleUserInfoToggle}
            onMouseEnter={handleUserInfoToggle}
            onMouseLeave={handleUserInfoToggle}
            className="text-gray-600 flex items-center font-semibold text-sm font-sans"
          >
            <span className="bg-gray-300 p-1 rounded-full mr-2 hover:bg-hxmBlue-200 transition-colors duration-300"><IoIosInformationCircleOutline className="text-2xl" /></span>
            {sidebarOpen && <span className='font-sans'>Info</span>}
          </button>
          {showUserInfo && (
            <div ref={userInfoPopupRef} className="absolute left-full top-0 mt-2 ml-4 w-64 p-4 bg-white rounded-lg shadow-lg z-10">
              <h3 className="text-lg font-bold mb-2">Admin Guide</h3>
              <p>Chargez les documents et classez les dans des collections pour permettre le filtrage</p>
            </div>
          )}
        </div>
      </div>
      <div className="p-4 flex items-center">
        <button onClick={handleLogout} className="text-gray-600 flex items-center font-semibold text-md font-sans">
          <span className="bg-gray-300 p-1 rounded-full mr-2 hover:bg-hxmBlue-200 transition-colors duration-300">
            <RiLogoutCircleLine className="text-2xl" />
          </span>
          {sidebarOpen && <span className="font-sans">Logout</span>}
        </button>
      </div>
    </div>
  );
};

export default Sidebar;

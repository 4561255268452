import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { RxCross2 } from 'react-icons/rx';
import { useNavigate, useParams } from 'react-router-dom';

function DocumentOptionsPopup({ documentId, onClose, setIsOptionLoading }) {
  const [folders, setFolders] = useState([]);
  const [documentFolders, setDocumentFolders] = useState([]);
  const [selectedFolders, setSelectedFolders] = useState([]);
  const popupRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  const fetchDocumentFolders = async () => {
    const token = Cookies.get('token');
    setIsOptionLoading(true); // Start loading
    try {
      const response = await axios.get(`https://tessibackend.hexamind.ai/admin/document/${documentId}/folders`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setDocumentFolders(response.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate('/login');
      } else {
        console.error('Error fetching document folders:', error);
      }
    } finally {
      setIsOptionLoading(false); // Stop loading
    }
  };

  useEffect(() => {
    const fetchFolders = async () => {
      const token = Cookies.get('token');
      setIsOptionLoading(true); // Start loading
      try {
        const response = await axios.get('https://tessibackend.hexamind.ai/admin/folders', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setFolders(response.data);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          navigate('/login');
        } else {
          console.error('Error fetching folders:', error);
        }
      } finally {
        setIsOptionLoading(false); // Stop loading
      }
    };

    fetchFolders();
    fetchDocumentFolders();
  }, [documentId]);

  const handleAddFoldersToDocument = async () => {
    const token = Cookies.get('token');
    setIsOptionLoading(true); // Start loading
    try {
      for (const folderId of selectedFolders) {
        await axios.put(`https://tessibackend.hexamind.ai/admin/document/add_folder?document_id=${documentId}&folder_id=${folderId}`, null, {
          headers: {
            Authorization: `Bearer ${token}`,
            accept: 'application/json'
          }
        });
      }
      setSelectedFolders([]);
      fetchDocumentFolders(); // Refresh the document folders
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate('/login');
      } else {
        console.error('Error adding folders to document:', error);
      }
    } finally {
      setIsOptionLoading(false); // Stop loading
    }
  };

  const handleRemoveFolderFromDocument = async (folderId) => {
    const token = Cookies.get('token');
    setIsOptionLoading(true); // Start loading
    try {
      await axios.put(`https://tessibackend.hexamind.ai/admin/document/remove_folder?document_id=${documentId}&folder_id=${folderId}`, null, {
        headers: {
          Authorization: `Bearer ${token}`,
          accept: 'application/json'
        }
      });
      fetchDocumentFolders(); // Refresh the document folders
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate('/login');
      } else {
        console.error('Error removing folder from document:', error);
      }
    } finally {
      setIsOptionLoading(false); // Stop loading
    }
  };

  const toggleFolderSelection = (folderId) => {
    if (selectedFolders.includes(folderId)) {
      setSelectedFolders(selectedFolders.filter(id => id !== folderId));
    } else {
      setSelectedFolders([...selectedFolders, folderId]);
    }
  };

  const availableFolders = folders.filter(folder => !documentFolders.some(docFolder => docFolder._id === folder._id));

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
      <div ref={popupRef} className="bg-white p-6 rounded-lg shadow-lg max-w-5xl w-full overflow-y-auto max-h-full relative">
        <RxCross2 className="absolute top-4 right-4 text-2xl cursor-pointer text-gray-600 hover:text-hxmBlue-600" onClick={onClose} />
        <h2 className="text-2xl font-bold text-gray-600 mb-4">Gérer les collections</h2>

        <div className="mb-6">
          <h3 className="text-lg font-medium text-gray-700">Collections associées aux documents</h3>
          <div className="grid grid-cols-4 gap-4 mt-2">
            {documentFolders.map(folder => (
              <div key={folder._id} className="p-4 bg-gray-100 rounded-lg flex justify-between items-center relative">
                <span>{folder.name}</span>
                <RxCross2 className="absolute top-2 right-2 text-hxmBlue-600 cursor-pointer hover:text-hxmBlue-800" onClick={() => handleRemoveFolderFromDocument(folder._id)} />
              </div>
            ))}
          </div>
        </div>

        <div className="mb-6">
          <h3 className="text-lg font-medium text-gray-700">Collections disponibles</h3>
          <div className="grid grid-cols-4 gap-4 mt-2">
            {availableFolders.map(folder => (
              <div
                key={folder._id}
                className={`p-4 bg-gray-100 rounded-lg flex justify-between items-center cursor-pointer ${selectedFolders.includes(folder._id) ? 'border-2 border-hxmBlue-500' : ''}`}
                onClick={() => toggleFolderSelection(folder._id)}
              >
                <span>{folder.name}</span>
              </div>
            ))}
          </div>
        </div>

        <div className="flex justify-end mt-4">
          <button
            onClick={handleAddFoldersToDocument}
            className="bg-hxmBlue-500 text-white py-2 px-4 rounded-lg hover:bg-hxmBlue-600 transition-colors duration-300"
          >
            Ajouter
          </button>
        </div>
      </div>
    </div>
  );
}

export default DocumentOptionsPopup;
